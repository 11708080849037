import { ParsedQs } from "qs";

import { SurfaceableError } from "./SurfaceableError";

export interface PaginationResult<T> {
  totalCount: number;
  totalPages: number;
  pageSize: number;
  pageNumber: number;
  data: T[];
}

export interface ILoadPaginatedForOrg {
  queryParams?: IDeserializedPaginatedQuery;
}

export type SmartrrSortType = "ASC" | "DESC";

export type RSuiteSortType = Lowercase<SmartrrSortType>;

export interface SmartrrFilterType {
  [key: string]: string | string[] | undefined;
}

const normalizeFilterValues = (filter: { [key: string]: string }) => {
  const finalFilter: NonNullable<ISerializedPaginatedQuery["filterEquals" | "filterLike"]> = {};

  for (const [field, value] of Object.entries(filter)) {
    const finalValue = value && String(value).trim();
    if (finalValue) {
      finalFilter[field] = finalValue;
    }
  }

  return finalFilter;
};

export interface ISerializedPaginatedQuery extends ParsedQs {
  pageSize?: string;
  pageNumber?: string;
  orderBy?: {
    [key: string]: SmartrrSortType;
  };
  filterEquals?: {
    [key: string]: string;
  };
  filterIn?: {
    [key: string]: string[];
  };
  filterLike?: {
    [key: string]: string;
  };
  filterBefore?: {
    [key: string]: string;
  };
  filterAfter?: {
    [key: string]: string;
  };
}

export interface IFiltersWithDeserializedPaginatedQuery {
  orders?: Pick<IDeserializedPaginatedQuery, "orderBy">;
  bills?: Pick<IDeserializedPaginatedQuery, "orderBy">;
}

export interface IDeserializedPaginatedQuery {
  pageSize: number;
  pageNumber: number;
  orderBy?: {
    [key: string]: SmartrrSortType;
  };
  filterEquals?: {
    [key: string]: string;
  };
  filterIn?: {
    [key: string]: string[];
  };
  filterLike?: {
    [key: string]: string;
  };
  filterBefore?: {
    [key: string]: string;
  };
  filterAfter?: {
    [key: string]: string;
  };
}

export const DEFAULT_PAGE_NUMBER = 0;
export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_ADDONS_PAGE_SIZE = 25;

export const MIN_PAGE_NUMBER = 0;
export const MIN_PAGE_SIZE = 1;
export const MAX_PAGE_SIZE = 250;

export function deserializePaginatedQuery(queryObject: ISerializedPaginatedQuery): IDeserializedPaginatedQuery {
  let pageSize =
    typeof queryObject.pageSize === "string"
      ? +queryObject.pageSize.trim() || DEFAULT_PAGE_SIZE
      : DEFAULT_PAGE_SIZE;
  let pageNumber =
    typeof queryObject.pageNumber === "string"
      ? +queryObject.pageNumber.trim() || DEFAULT_PAGE_NUMBER
      : DEFAULT_PAGE_NUMBER;

  // If after the normalization, we get NaN, Infinite or a value lesser than 0, we need to set the fallback value
  if (!Number.isFinite(pageSize) || pageSize <= 0) {
    pageSize = DEFAULT_PAGE_SIZE;
  }

  // If after the normalization, we get NaN, Infinite or a value lesser than 1, we need to set the fallback value
  if (!Number.isFinite(pageNumber) || pageNumber < 0) {
    pageNumber = 0;
  }

  const orderBy = queryObject.orderBy;
  const filterLike = queryObject.filterLike;
  const filterEquals = queryObject.filterEquals;
  const filterIn = queryObject.filterIn;
  const filterBefore = queryObject.filterBefore;
  const filterAfter = queryObject.filterAfter;

  if (pageNumber < MIN_PAGE_NUMBER) {
    throw new SurfaceableError(`page number provided ${pageNumber} is under min of ${MIN_PAGE_NUMBER}`);
  }

  if (pageSize < MIN_PAGE_SIZE) {
    throw new SurfaceableError(`page size provided ${pageSize} is under min of ${MIN_PAGE_SIZE}`);
  }

  if (pageSize > MAX_PAGE_SIZE) {
    throw new SurfaceableError(`page size provided ${pageSize} is over max of ${MAX_PAGE_SIZE}`);
  }

  return {
    pageSize,
    pageNumber,
    filterLike: filterLike ? normalizeFilterValues(filterLike) : undefined,
    filterEquals: filterEquals ? normalizeFilterValues(filterEquals) : undefined,
    filterIn,
    orderBy: orderBy
      ? Object.keys(orderBy).reduce((accum: NonNullable<IDeserializedPaginatedQuery["orderBy"]>, field) => {
          const value = orderBy[field];
          accum[field] = value || "DESC";
          return accum;
        }, {})
      : undefined,
    filterBefore: filterBefore
      ? Object.keys(filterBefore).reduce(
          (accum: NonNullable<IDeserializedPaginatedQuery["filterBefore"]>, field) => {
            const value = filterBefore[field];
            accum[field] = value || "";
            return accum;
          },
          {}
        )
      : undefined,
    filterAfter: filterAfter
      ? Object.keys(filterAfter).reduce(
          (accum: NonNullable<IDeserializedPaginatedQuery["filterAfter"]>, field) => {
            const value = filterAfter[field];
            accum[field] = value || "";
            return accum;
          },
          {}
        )
      : undefined,
  };
}

export function serializePaginatedQueryValues(
  deserializedPaginatedQuery: IDeserializedPaginatedQuery | undefined
): ISerializedPaginatedQuery {
  const {
    pageSize = DEFAULT_PAGE_SIZE,
    pageNumber = DEFAULT_PAGE_NUMBER,
    orderBy,
    filterBefore,
    filterAfter,
    filterLike,
    filterEquals,
    filterIn,
  } = deserializedPaginatedQuery || {};

  return {
    pageSize: `${pageSize}`,
    pageNumber: `${pageNumber}`,
    filterLike: filterLike ? normalizeFilterValues(filterLike) : undefined,
    filterEquals: filterEquals ? normalizeFilterValues(filterEquals) : undefined,
    filterIn,
    orderBy: orderBy
      ? Object.keys(orderBy).reduce((accum: NonNullable<ISerializedPaginatedQuery["orderBy"]>, field) => {
          const value = orderBy[field];
          accum[field] = value || "DESC";
          return accum;
        }, {})
      : undefined,
    filterBefore: filterBefore
      ? Object.keys(filterBefore).reduce(
          (accum: NonNullable<ISerializedPaginatedQuery["filterBefore"]>, field) => {
            const value = filterBefore[field];
            accum[field] = value || "";
            return accum;
          },
          {}
        )
      : undefined,
    filterAfter: filterAfter
      ? Object.keys(filterAfter).reduce((accum: NonNullable<ISerializedPaginatedQuery["filterAfter"]>, field) => {
          const value = filterAfter[field];
          accum[field] = value || "";
          return accum;
        }, {})
      : undefined,
  };
}
